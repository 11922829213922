import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';

function categoriesSlider() {
    const sliderElements = document.querySelectorAll(
        `.js-categories-slider-container`
    );

    sliderElements.forEach((slider, index) => {
        slider.classList.add('js-categories-slider-' + index);

        let sliderSelector = slider.querySelector(
            `.js-categories-slider-${index} .swiper`
        );

        const swiper = new Swiper(sliderSelector, {
            modules: [Navigation],
            slidesPerView: 'auto',
            spaceBetween: 8,
            navigation: {
                prevEl: '.js-infinity-swiper-button-prev',
                nextEl: '.js-infinity-swiper-button-next',
            },
            loop: true,
            // breakpoints: {
            //     1500:{
            //         slidesPerView: 8.2
            //     },
            //     1200: {
            //         slidesPerView: 5.1,
            //     },
            //     768: {
            //         slidesPerView: 4.1,
            //     },
            //     600: {
            //         slidesPerView: 3.1,
            //     },
            //     420: {
            //         slidesPerView: 2.2,
            //     },
            //     320: {
            //         slidesPerView: 1.8,
            //     },
            // },
        });
    });
}

categoriesSlider();
